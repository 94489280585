$(document).on("change", "#new_task [data-url], [id^='edit_task_'] [data-url]", function(event) {
  event.preventDefault()

  let data = $(this).parents("form").serialize();
  data += "&source_filter=" + $(this).attr('id');
  $.ajax({
    url: $(this).data("url"),
    type: "get",
    data: data,
    dataType: "script"
  })
});

function changeTaskFormItemsVisibility({ hide = [], show = [], forceParentRow = [] }) {
  [...hide, ...show].forEach(item => {
    const elements = $('.' + item);
    if (!elements.length) {
      console.warn('Selector not found: .' + item);
      return;
    }
    elements.each(function () {
      const parentElement = $(this).parent('.row');
      const target =
        forceParentRow.includes(item) ? parentElement :
        (parentElement.children().length === 1 ? parentElement : $(this));
      target.toggleClass("d-none", hide.includes(item));
    });
  });
}

const TASK_FORM_ITEMS_FORCE_PARENT_ROW = ['task_value'];

const TASK_FORM_KIND_CONFIG = {
  value: {
    show: ['task_value', 'task_requires_photo', 'task_requires_signature'],
    hide: ['task_supplier_ids', 'task_storage_ids', 'task_recipient_email']
  },
  order: {
    show: ['task_supplier_ids'],
    hide: ['task_storage_ids', 'task_value', 'task_recipient_email', 'task_requires_photo', 'task_requires_signature']
  },
  inventory: {
    show: ['task_storage_ids'],
    hide: ['task_supplier_ids', 'task_value', 'task_recipient_email', 'task_requires_photo', 'task_requires_signature']
  },
  confirmation: {
    show: ['task_requires_photo', 'task_requires_signature'],
    hide: ['task_supplier_ids', 'task_storage_ids', 'task_value', 'task_recipient_email']
  },
  default: {
    show: ['task_recipient_email', 'task_requires_photo', 'task_requires_signature'],
    hide: ['task_supplier_ids', 'task_storage_ids', 'task_value']
  }
};

$(document).on("change", "#task_kind", function() {
  const taskKind = $(this).val();
  let config = TASK_FORM_KIND_CONFIG[taskKind] || TASK_FORM_KIND_CONFIG.default;
  config = { ...config, forceParentRow: TASK_FORM_ITEMS_FORCE_PARENT_ROW };
  changeTaskFormItemsVisibility(config);

  const primaryTitle = $('.task_title input').data('primary-title');
  const secondaryTitle = $('.task_title input').data('secondary-title');
  const title = taskKind == "question" ? secondaryTitle : primaryTitle;
  $('.task_title label').html(title + " <abbr>*</abbr>");
});

function handleIntervalVisibility() {
  const isOneTime = $('#task_one_time')[0].checked;
  const hasWeekdays = $('#task_weekdays').val() != "";

  let intervalItems = { hide: [], show: [] };

  if (isOneTime) {
    intervalItems.hide.push('task_weekdays', 'task_weekly_interval', 'task_interval');
  } else {
    intervalItems.show.push('task_weekdays');
    intervalItems.show.push(hasWeekdays ? 'task_weekly_interval' : 'task_interval');
    intervalItems.hide.push(hasWeekdays ? 'task_interval' : 'task_weekly_interval');
  }

  changeTaskFormItemsVisibility(intervalItems);

  const startDateRequired = isOneTime;
  const label = $('label[for="task_start_date"]');
  const abbr = ' <abbr>*</abbr>';
  startDateRequired ? label.append(abbr) : label.find('abbr').remove();
}

$(document).on("change", "#task_one_time", handleIntervalVisibility);
$(document).on("change", "#task_weekdays", handleIntervalVisibility);

function handleTaskDueTime() {
  const times = {
    from: {
      hour: $("select[id^='task_valid_from_time']")[0].value,
      minute: $("select[id^='task_valid_from_time']")[1].value
    },
    due: {
      hour: $("select[id^='task_due_time']")[0].value,
      minute: $("select[id^='task_due_time']")[1].value
    }
  };

  const shouldAdjust = $("#task_due_on_day").val() === "0" &&
    (times.from.hour > times.due.hour ||
    (times.from.hour === times.due.hour && times.from.minute >= times.due.minute));

  if (shouldAdjust) {
    // Change to the following day
    $("#task_due_on_day").val(1).trigger("change");
  }
}

$(document).on("change", "select[id^='task_due_time']", handleTaskDueTime);

$(document).on("change", "#task_requires_photo", function() {
  if (this.checked && $('#task_requires_signature')[0].checked) {
    $('#task_requires_signature').prop( "checked", false ).trigger("change");
  }
  const visibility = this.checked ? 'show' : 'hide';
  changeTaskFormItemsVisibility({ [visibility]: ['task_photo_email'] });
});

$(document).on("change", "#task_requires_signature", function() {
  if (this.checked && $('#task_requires_photo')[0].checked) {
    $('#task_requires_photo').prop( "checked", false ).trigger("change");
  }
  const visibility = this.checked ? 'show' : 'hide';
  changeTaskFormItemsVisibility({ [visibility]: ['task_signature_email'] });
});

$(document).on("change", "#task_remind", function() {
  const visibility = this.checked ? 'show' : 'hide';
  changeTaskFormItemsVisibility({ [visibility]: ['task_remind_time'] });
});

$(document).on("change", "#task_specific_to_employees", function() {
  const visibility = this.checked ? 'show' : 'hide';
  changeTaskFormItemsVisibility({ [visibility]: ['task_specific_to_employee_ids'] });
});