$(document).on('click', ".select-default", function(event) {
  event.preventDefault()

  $(".default").val("0")
  $(this).parents(".article-card").find(".default").val("1")

  $(".select-default").removeClass("btn-success")
  $(".select-default").addClass("btn-primary")
  $(this).addClass("btn-success")

  const articleRemoveButtons = $(".article-card .remove_fields")
  articleRemoveButtons.removeClass("disabled");
  articleRemoveButtons.unwrap(".remove-fields-disabled");

  const articleRemoveButton = $(this).parents(".article-card").find(".remove_fields");
  articleRemoveButton.addClass("disabled");
  articleRemoveButton.wrap('<span class="remove-fields-disabled" data-toggle="tooltip" title="Dieser Artikel kann nicht gelöscht werden, da er in einer Filiale als Standard gesetzt ist."></span>');
})
