$(document).on("click", ".open-task, .complete-task", function(event) {
  event.preventDefault();
  $("#todo_status").val($(this).data("status"));
  $("form.edit_todo").submit();
})

$(document).on("input", "#todo_value", function() {
  var value = parseFloat($(this).val())
  var tolerance_range = $(this).data("value")

  if (value < tolerance_range[0] || value > tolerance_range[1]) {
    $(this).parents(".form-inputs").find("#todo_measure").parent().removeClass("d-none")
  } else {
    $(this).parents(".form-inputs").find("#todo_measure").parent().addClass("d-none")
  }
})

$(document).on("click", ".remote-form-container .submit-task", function(event) {
  var form = $(this).parents("form");
  var formData = new FormData(form[0]);
  event.preventDefault()

  $.ajax({
    url: form.attr("action"),
    type: form.attr("method"),
    data: formData,
    dataType: "script",
    processData: false,
    contentType: false,
    success: function() {
      window.initialize()
    }
  })
})

$(document).on("click", ".btn-toggle-impossible", function(event) {
  event.preventDefault()

  let task = $(this).closest('tr');
  let taskId = $(this).closest('tr').data('id');

  task.find('#edit_todo_' + taskId).toggle(".d-none")
  task.find('#impossible_edit_todo_' + taskId).toggle(".d-none")
});