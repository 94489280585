let
  signaturePad = null,
  signaturePadCanvas = null,
  targetFormId = null;

function initSignaturePad() {
  signaturePadCanvas = document.getElementById('signature-capture-canvas');
  signaturePad = new SignaturePad(signaturePadCanvas, {
    backgroundColor: 'rgb(255, 255, 255)'
  });
  resizeSignatureCanvas();
  signaturePad.clear();
}

function clearSignaturePad() {
  signaturePad.clear();
  signaturePad.off();
  signaturePad = null;
  signaturePadCanvas = null;
}

function resizeSignatureCanvas() {
  if (!signaturePadCanvas || !signaturePad) return;

  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  const oldWidth = signaturePadCanvas.width / ratio;
  const oldHeight = signaturePadCanvas.height / ratio;
  const newWidth = signaturePadCanvas.parentElement.clientWidth;
  const newHeight = signaturePadCanvas.parentElement.clientHeight;

  signaturePadCanvas.width = newWidth * ratio;
  signaturePadCanvas.height = newHeight * ratio;
  signaturePadCanvas.getContext("2d").scale(ratio, ratio);

  const signatureData = signaturePad.toData();
  if (!signatureData || !signatureData.length) return;

  const scaleX = newWidth / oldWidth;
  const scaleY = newHeight / oldHeight;
  signaturePad.fromData(signatureData.map(stroke => ({
      ...stroke,
      points: stroke.points.map(point => ({
          x: point.x * scaleX,
          y: point.y * scaleY,
          pressure: point.pressure,
          time: point.time
      }))
  })));
}

$(window).on('resize', function() {
  resizeSignatureCanvas();
});

function submitTodoFormWithSignature(signature) {
  if (signature == null || signature.size == 0) {
    console.error('No signature to submit');
    return;
  }

  let form = $("#" + targetFormId);
  let formData = new FormData(form[0]);
  formData.set('todo[signature]', signature);

  $.ajax({
    url: form.attr("action"),
    type: form.attr("method"),
    data: formData,
    dataType: "script",
    processData: false,
    contentType: false,
    success: function() {
      window.initialize()
    }
  });
}

$(document).on('click', '#clear-signature', function () {
  signaturePad.clear();
});

$(document).on("click", "#submit-signature", function(event) {
  event.preventDefault();
  if (signaturePad.isEmpty()) {
    dataConfirmModal.confirm({
      title: 'Keine Eingabe!',
      text: "Bitte unterschreiben Sie zuerst bevor Sie fortfahren.",
      cancel: function() {
        this.remove();
      }
    });

    return;
  }
  signaturePadCanvas.toBlob(submitTodoFormWithSignature, "image/jpeg", 0.95);
  $('#signature-modal').modal('hide');
});

$(document).on('shown.bs.modal', '#signature-modal', function () {
  initSignaturePad();
  $('#clear-signature').show();
  $('#submit-signature').show();
});

$(document).on('hide.bs.modal', '#signature-modal', function () {
  clearSignaturePad();
  $('#clear-signature').hide();
  $('#submit-signature').hide();
});

$(document).on('click', '.open-signature-capture', function(event) {
  event.preventDefault();
  targetFormId = $(this).data('target-form');
  $('#signature-modal').modal('show');
});
